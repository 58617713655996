<template>
  <div class="banner">
    <img ref="image" :src="image">
  </div>
</template>

<script>
import { FocusedImage } from 'image-focus'

export default {
  name: 'HelloWorld',
  props: {
    image: {
      type: String
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    focusedImage: false
  }),
  mounted () {
    this.focusedImage = new FocusedImage(this.$refs.image, {
      focus: {
        x: this.x,
        y: this.y
      }
    })
  }
}
</script>

<style scoped lang="scss">
</style>
