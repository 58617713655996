<template>
  <div class="locale-changer">
    <div class="locale" v-for="lang in langs" :key="'lang' + lang.code" @click="$i18n.locale = lang.code">
      {{ lang.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return {
      langs: [
        {
          component: 'ZhCn',
          code: 'zh',
          name: 'Chinese/中文'
        },
        {
          component: 'En',
          code: 'en',
          name: 'English'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.locale-changer {
  display: flex;
  margin: 0 -5px;
  justify-content: flex-end;
}

.locale {
  margin: 0 5px;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #FFF;

  :global(svg) {
    width: auto;
    height: 20px;
  }
}
</style>
