<template>
  <div class="menu">
    <div class="title" v-if="title !== null">
      {{ title }}
    </div>
    <div class="items">
      <div @click="$emit('menu:click', item)" :class="{ item: true, active: isActive(item) }" v-for="(item, index) of items" :key="index">
        <div class="icon" v-if="item.icon">
          <router-link :to="item.router">
            <component :is="item.icon" />
          </router-link>
        </div>
        <div class="name">
          <router-link :to="item.router">
            {{ $t('header.menu.' + item.name) }}
          </router-link>
        </div>
      </div>
      <div class="no-items" v-if="items.length === 0">
        No menu items
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    isActive (item) {
      if (this.$route.name === item.router.name) return true
      if (this.$route.meta && this.$route.meta.activeMenus && this.$route.meta.activeMenus.includes(item.router.name)) return true
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  $textColour: #fff;
  $textActive: #00becb;
  $textHover: $textActive;

  .menu {
    a {
      color: $textColour;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .items {
      width: 100%;
      .item {
        display: flex;
        margin-bottom: 15px;

        .icon {
          a {
            padding-left: 8px;
          }
        }

        .name {
          align-self: center;
          transition: all 250ms ease-in-out;
          flex-grow: 1;
          text-align: left;

          position: relative;

          &:after {
            background: $textActive;
            height: 3px;
            display: block;
            transition: all 250ms ease-in-out;
            width: 1px;
            opacity: 0;
            position: absolute;
            content: "";
            bottom: -10px;
            left: 21%;
            z-index: 1000;
          }

          a {
            transition: all 250ms ease-in-out;
            display: block;
            width: 100%;
            line-height: 1;
          }
        }

        &.active,
        &:hover {
          a {
            text-decoration: none;
          }

          .name {
            position: relative;

            &:after {
              width: 60%;
              opacity: 1;
            }
          }

        }
      }
    }
  }
</style>
