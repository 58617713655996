<template>
  <div class="home">
    <banner class="home" :image="require('@/assets/cityscape.jpg')" :x="0.30" :y="0.04" />

    <div class="content home">
      <div class="container">
        <content-section>
          <template v-slot:col1>
            <div class="col1">
              <div class="title">
                {{ $t('home.title.main') }}
                <div class="sub">
                  <div class="inner">
                    {{ $t('home.title.sub') }}
                  </div>
                </div>
              </div>

              <p v-for="(text, index) of $t('home.col1')" :key="'home.col1.' + index">
                {{ text }}
              </p>
            </div>
          </template>
          <template v-slot:col2>
            <div class="col2">
              <p v-for="(text, index) of $t('home.col2')" :key="'home.col2.' + index">
                {{ text }}
              </p>
            </div>
          </template>
        </content-section>
      </div>
    </div>

    <banner :image="require('@/assets/global-connections.jpg')" :y="0.07" />

    <div class="content about">
      <div class="container">
        <content-section>
          <template v-slot:col1>
            <div class="col1">
              <div class="image">
                <img src="@/assets/distribution.png" />
              </div>
              <div class="text">
                <div class="title">
                  {{ $t('about.title') }}
                  <div class="underline"></div>
                </div>

                <p v-for="(text, index) of $t('about.col1')" :key="'home.col1.' + index">
                  {{ text }}
                </p>
              </div>
            </div>
          </template>
        </content-section>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import ContentSection from '@/components/ContentSection'

export default {
  name: 'home',
  components: {
    Banner,
    ContentSection
  }
}
</script>

<style lang="scss" scoped>
.banner {
  &.home {
    margin-top: 10px;
  }

  height: 65vh;
  overflow: hidden;
}

.content.home {
  padding: 70px 0;
  position: relative;

  .container {
    max-width: 80%;
  }

  .col1 p {
    padding-left: 70px;
  }

  .col2 {
    margin-top: 96px;
  }

  .title {
    font-family: 'Kollektif Bold', 'Kollektif', sans-serif;
    color: #767779;
    font-size: 40px;
    font-weight: normal;
    line-height: 1em;
    margin-bottom: 30px;

    .sub {
      font-size: 30px;
      margin-left: 70px;

      .inner {
        line-height: 1.2em;
        display: inline-block;
        padding: 0 0 2px 20px;
        border-bottom: 3px solid #0099cb;
      }
    }
  }
}

.content.about {
  padding: 50px 0;
  position: relative;

  .col1 {
    flex-grow: 1;

    img {
      width: 40%;
      float: left;
      shape-outside: polygon(0% 0%, 79% 0%, 100% 53%, 82% 100%, 0% 100%);
      padding-right: 60px;
      padding-top: 17px;
    }

    .title {
      font-family: 'Kollektif Bold', 'Kollektif', sans-serif;
      color: #767779;
      font-size: 30px;
      font-weight: normal;
      line-height: 1em;
      margin-bottom: 10px;
      display: inline-block;

      .underline {
        display: block;
        width: 35%;
        padding: 13px 0 2px 20px;
        margin-left: 40px;
        border-bottom: 3px solid #0099cb;
      }
    }
  }
}

@media (max-width: 767px) {
  .banner {
    height: 40vh;
  }
  .content {
    &.home,
    &.about {
      .container {
        max-width: 100%;
      }
    }

    &.home {
      .col1 {
        p {
          padding-left: 0;
        }
      }

      .col2 {
        margin-top: 0;
      }
    }

    &.about {
      .col1 {
        display: flex;
        flex-wrap: wrap;

        .image {
          order: 2;
        }

        .text {
          order: 1;
        }

        img {
          float: none;
          width: 100%;
          margin-bottom: 30px;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
