<template>
  <div class="layout">
    <header class="header">
      <div class="container">
        <div class="logo">
          <main-logo />
        </div>
        <div class="navbar">
          <icon-bars class="menu-toggle" @click="menuToggle = !menuToggle" />
          <language-selector />
          <nav-bar @menu:click="menuToggle = false" :class="{ open: menuToggle }" :items="mainMenu" />
        </div>
      </div>
    </header>
    <div class="page-body">
      <slot />
      <router-view name="secondary"></router-view>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="logo">
          <footer-logo />
        </div>
        <div class="contact">
          <div class="title">
            Eco Concepts (UK) Ltd
          </div>
          <div class="col">
            {{ $t('footer.contact.company-number') }} 11602729<br />
            {{ $t('footer.contact.email') }} <a href="mailto:">info@ecoconcepts.co</a>
          </div>
          <div class="col">
            2 Nichols Green<br />
            Montpelier Road London W5 2QU
          </div>
        </div>
        <div class="social">
          <div class="title">
            {{ $t('footer.social.title') }}
          </div>
          <div class="icons">
            <div class="icon facebook">
              <a href="http://www.facebook.com/ethan.lu.79827" target="_blank">
                <icon-facebook />
              </a>
            </div>
            <div class="icon twitter">
              <a href="#" target="_blank">
                <icon-twitter />
              </a>
            </div>
            <div class="icon">
              <a href="http://www.instagram.com/proxy_uk/" target="_blank">
                <icon-instagram />
              </a>
            </div>
            <div class="icon">
              <a href="#" target="_blank">
                <icon-linkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="legal">
      <div class="container">
        <div class="copyright">
          {{ $t('footer.legal')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/assets/logo.svg'
import FooterLogo from '@/assets/logo-short.svg'
import NavBar from '@/components/NavBar'
import LanguageSelector from '@/components/LanguageSelector'
import IconFacebook from '@/components/facebook.svg'
import IconTwitter from '@/components/twitter.svg'
import IconInstagram from '@/components/instagram.svg'
import IconBars from '@/components/bars.svg'
import IconLinkedin from '@/components/linkedin.svg'

export default {
  name: 'DefaultLayout',
  components: {
    MainLogo,
    NavBar,
    FooterLogo,
    LanguageSelector,
    IconFacebook,
    IconTwitter,
    IconInstagram,
    IconBars,
    IconLinkedin
  },
  data: () => ({
    navCollapsed: false,
    navHover: false,
    menuToggle: false,
    mainMenu: [
      {
        name: 'home',
        section: 'home',
        router: {
          name: 'home',
          section: 1
        }
      },
      {
        name: 'products',
        router: {
          name: 'products'
        }
      },
      // {
      //   name: 'about',
      //   section: 'about',
      //   router: {
      //     name: 'about'
      //   }
      // },
      {
        name: 'clients',
        router: {
          name: 'clients'
        }
      },
      {
        name: 'suppliers',
        router: {
          name: 'suppliers'
        }
      },
      {
        name: 'contact',
        router: {
          name: 'contact'
        }
      }
    ]
  })
}
</script>

<style lang="scss">
  body {
    padding: 0;
    margin: 0;
  }
  @media (min-width: 768px) {
    .layout {
      padding-top: 124px;
    }
  }

  .header {
    background: #767779;
    padding: 35px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .locale-changer {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .container {
      display: flex;
      justify-content: space-between;
    }

    .logo {
      svg {
        height: 5vh;
        width: 100%;
      }
    }

    .navbar {
      align-self: flex-end;

      .menu-toggle {
        display: none;
      }

      .menu {
        font-family: 'Work Sans', sans-serif;
        font-weight: bold;
        font-size: 18px;

        .items {
          display: flex;
          margin: 0 -5px 5px -5px;

          .item {
            margin: 0 5px;
            a {
              padding: 0 10px;
            }
          }
        }
      }
    }

    .container {
      padding: 0 10px;
    }
  }

  .footer {
    background: #767779;
    color: #FFF;
    padding: 45px 0;

    a {
      color: #FFF;

      &:hover {
        color: #00becb;
      }
    }

    .container {
      display: flex;
      justify-content: space-between;

      .logo {
        align-self: center;
        svg {
          width: auto;
          height: 50px;
        }
      }

      .contact {
        font-size: 16px;
        color: #FFF;
        font-family: 'Work Sans', sans-serif;
        display: grid;
        grid-column-gap: 45px;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 15px;

        .title {
          grid-column: 1 / 3;
        }
      }

      .menu {
        align-self: center;

        .items {
          font-size: 16px;
          color: #FFF;
          display: flex;
          flex-wrap: wrap;
          font-family: 'Work Sans', sans-serif;

          .item {
            flex-basis: 50%;
            margin: 5px 0;

            &:nth-of-type(1) {
              order: 1;
            }

            &:nth-of-type(2) {
              order: 3;
            }
            &:nth-of-type(3) {
              order: 5;
            }

            &:nth-of-type(4) {
              order: 2;
            }
            &:nth-of-type(5) {
              order: 4;
            }

            &:nth-of-type(6) {
              order: 6;
            }
          }

          a {
            text-decoration: none;
            color: #FFF;

            &:hover {
              transition: all 150ms ease-in-out;
              color: #00becb;
            }
          }
        }
      }

      .social {
        .title {
          font-size: 16px;
          font-family: 'Work Sans', sans-serif;
          padding-right: 35px;
          padding-bottom: 15px;
          border-bottom: 2px solid #00becb;
        }

        .icons {
          display: flex;
          margin: 20px -14px 0 -7px;
          .icon {
            margin: 0 7px;
            background: #FFF;
            border-radius: 100%;
            border: 1px solid #000;
            width: 40px;
            height: 40px;
            display: flex;
            transition: all 150ms ease-in-out;

            &:hover {
              background: #00becb;

              :global(svg) {
                fill: #fff;
              }
            }

            a {
              display: flex;
              width: 100%;
              height: 100%;
              align-content: center;
              justify-content: center;

              :global(svg) {
                align-self: center;
                height: 20px;
                transition: all 150ms ease-in-out;
                fill: #000;
              }
            }
          }
        }
      }
    }
  }

  .legal {
    font-family: 'Work Sans', sans-serif;
    background: #00becb;
    color: #222222;
    padding: 12px 0 10px 0;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    .container {
      padding: 0 15px;
      max-width: 90%;
      margin: 0 auto;
    }
  }
  @media (max-width: 767px) {
    .footer {
      .container {
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;

        .contact {
          margin: 15px 0;
          grid-template-columns: 1fr;

          .title {
            grid-column: 1 / 2;
          }
        }

        .menu {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }
    }

    .legal {
      padding-left: 15px;
      padding-right: 15px;
    }

    .header {
      position: static;

      .container {
        justify-content: center;
      }

      .logo {
        position: relative;
        top: 10px;
      }

      .navbar {
        .menu-toggle {
          position: absolute;
          display: block;
          top: 10px;
          left: 10px;
          z-index: 10000;
          width: 25px;
          height: 25px;
          fill: #FFF;
          stroke: #FFF;
        }

        .menu {
          position: fixed;
          top: 123px;
          right: auto;
          left: -55%;
          bottom: 0;
          width: 55%;
          transition: all 250ms ease-in-out;
          box-sizing: border-box;
          background: #767779;
          z-index: 10000;
          border-right: 3px solid #00becb;

          &.open {
            left: 0;
          }

          .items {
            flex-wrap: wrap;

            .item {
              width: 100%;
              font-size: 24px;
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
</style>
