<template>
  <div class="content-section">
    <slot name="col1" />
    <slot name="col2" />
  </div>
</template>

<script>
export default {
  name: 'ContentSection'
}
</script>

<style lang="scss" scoped>
.content-section {
  display: flex;
  margin: 0 -30px;

  font-family: 'Work Sans', sans-serif;;
  font-size: 16px;
  line-height: 24px;

  > div {
    margin: 0 30px;
  }

  p, ul {
    font-family: 'Work Sans', sans-serif;
    font-size: 19px;
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .content-section {
    flex-wrap: wrap;
    margin: 0;
    padding: 0 15px;

    > div {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
