<template>
  <div id="app">
    <component :is="layout">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'

export default {
  components: {
    DefaultLayout
  },
  computed: {
    layout () {
      return this.$route.meta.layout || 'DefaultLayout'
    }
  }
}
</script>
