import { render, staticRenderFns } from "./ContentSection.vue?vue&type=template&id=036fc0de&scoped=true&"
import script from "./ContentSection.vue?vue&type=script&lang=js&"
export * from "./ContentSection.vue?vue&type=script&lang=js&"
import style0 from "./ContentSection.vue?vue&type=style&index=0&id=036fc0de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036fc0de",
  null
  
)

export default component.exports